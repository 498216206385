const formatTime = (totalSeconds: number, extension?: string) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const ext = extension ? ` ${extension}` : '';

  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}${ext}`;
};

export const formatSecondsToMinutes = (totalSeconds: number, extension?: string) => {
  return formatTime(Math.floor(totalSeconds), extension);
};

export const formatMillisecondsToMinutes = (totalMilliseconds: number, extension?: string) => {
  return formatTime(Math.floor(Math.floor(totalMilliseconds) / 1000), extension);
};
